import { useState } from "react";
import { Grid, SwipeableDrawer, Typography } from "@mui/material";
import { ExpandLess, KeyboardArrowDown } from "@mui/icons-material";
import QrReader from 'react-qr-reader';
import { isMobile } from "wcz-layout";

interface QrScannerProps {
    onScan: (data: string) => void
}

export default function QrScanner(props: QrScannerProps) {
    const { onScan } = props;
    const [open, setOpen] = useState(false);

    const handleOnScan = (data: string | null) => {
        if (data) {
            const ratingId: string = data.split('create/')[1];
            onScan(ratingId);
        }
    };

    if (!isMobile)
        return null;

    const bottomDrawer = (
        <SwipeableDrawer anchor="bottom" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} ModalProps={{ keepMounted: true }}>
            <Typography sx={{ textAlign: 'center' }}><KeyboardArrowDown /></Typography>
            {open && <QrReader onError={error => console.log(error)} onScan={handleOnScan} style={{ width: '100%' }} />}
        </SwipeableDrawer>
    );

    return (
        <Grid container spacing={2} sx={{ position: 'fixed', bottom: 0 }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <ExpandLess />
            </Grid>

            {bottomDrawer}
        </Grid>
    );
}