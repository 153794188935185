import { isDevelopment } from 'wcz-layout';

let apiUrl: string = "https://api.dev.wistron.eu/smart-cleaning";
let fileUrl: string = "https://api.dev.wistron.eu/file";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/smart-cleaning";
    fileUrl = "https://api.wistron.eu/file";
}

//export microservices base URL
export { apiUrl, fileUrl };
