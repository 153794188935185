import { Autocomplete, Container, TextField, Typography } from '@mui/material';
import { Fragment, useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { fetchGet, isMobile, LayoutContext } from 'wcz-layout';
import QrScanner from '../components/home/QrScanner';
import Location from '../models/Location';
import { apiUrl } from '../utils/BaseUrl';

export default function HomePage() {
    const { changeTitle, t } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data: locations = [] } = useQuery<Location[]>(["locations"], ({ signal }) => fetchGet(apiUrl + "/v1/location", signal));

    useEffect(() => changeTitle("Smart cleaning"), []);

    const handleOnChange = (value: Location | null) => {
        if (value)
            navigate('/rating/create/' + value.id);
    };

    return (
        <Fragment>
            <Container sx={{ mt: 5 }}>
                {!isMobile && <Typography variant="h6" gutterBottom>{t("SelectFromTheList")}</Typography>}

                <Autocomplete
                    options={locations}
                    getOptionLabel={option => option.name}
                    onChange={(e, value) => handleOnChange(value)}
                    autoHighlight
                    renderInput={params => <TextField {...params} variant="outlined" margin="dense" label={t("Locations")} autoFocus={!isMobile} />} />
            </Container>

            <QrScanner onScan={data => navigate('/rating/create/' + data)} />
        </Fragment>
    );
}