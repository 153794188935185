import { QRCodeCanvas } from 'qrcode.react';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import Location from '../../models/Location';
import { useQuery } from '@tanstack/react-query';
import { fetchGet } from 'wcz-layout';
import { apiUrl } from '../../utils/BaseUrl';

interface GenerateQrProps {
    locationId: string,
    setLocationId: (locationId: string) => void
}

export default function GenerateQr(props: GenerateQrProps) {
    const { locationId, setLocationId } = props;

    const { data: location = {} as Location } = useQuery<Location>(["location", locationId], ({ signal }) => fetchGet(apiUrl + "/v1/location/" + locationId, signal), {
        enabled: !!locationId
    });

    const onClose = () => setLocationId("");

    return (
        <Dialog open={!!locationId} onClose={onClose} fullWidth sx={{ textAlign: 'center' }}>
            <Grid container justifyContent="space-between" sx={{ height: 50 }}>
                <Grid item>
                    <DialogTitle>{location.name}</DialogTitle>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                    <IconButton onClick={onClose}>
                        <Close fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                <QRCodeCanvas value={window.location.origin + "/rating/create/" + locationId} size={256} />
            </DialogContent>
        </Dialog>
    );
}