import { Card, CardContent, Container, Rating, Typography } from "@mui/material";
import moment from "moment";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { FileViewer } from 'wcz-file';
import { LayoutContext } from "wcz-layout";
import Location from "../models/Location";
import { useGetLocation } from "../services/LocationService";

export default function Ratings() {
    const { locationId } = useParams();
    const { changeTitle } = useContext(LayoutContext);

    const { data: location = {} as Location } = useGetLocation(locationId, {
        onSuccess: data => changeTitle(data.name)
    });

    return (
        <Container>
            {location.ratings?.map(rating =>
                <Card sx={{ my: 2 }} key={rating.id}>
                    <CardContent>
                        <Typography variant="body2">{moment(rating.created).formatDateTime()}</Typography>
                        <Rating value={rating.value} readOnly size="small" />
                        <Typography variant="body1">{rating.remark}</Typography>

                        <FileViewer subId={rating.id} disableActions />
                    </CardContent>
                </Card>
            )}
        </Container>
    );
}