import { Card, CardActions, CardContent, CardHeader, Checkbox, Container, FormControlLabel, FormGroup, Rating, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FileViewer, UploadFile } from "wcz-file";
import { LayoutContext, fetchPost, newGuid } from "wcz-layout";
import LoadingButton from "../components/common/LoadingButton";
import Location from "../models/Location";
import RatingModel from "../models/Rating";
import { useGetLocation } from "../services/LocationService";
import { apiUrl } from "../utils/BaseUrl";

export default function AddRating() {
    const { locationId } = useParams();
    const { changeTitle, snackbar, t, i18n } = useContext(LayoutContext);
    const [rating, setRating] = useState<RatingModel>({} as RatingModel);
    const navigate = useNavigate();

    const { data: location = {} as Location } = useGetLocation(locationId, {
        onSuccess: data => setRating({ ...rating, id: newGuid(), locationId: data.id })
    });

    // eslint-disable-next-line
    useEffect(() => changeTitle(t("AddRating")), [i18n.language]);

    const { mutate } = useMutation((req: RatingModel) => fetchPost(`${apiUrl}/v1/rating`, req), {
        onSuccess: () => {
            navigate("/");
            snackbar({ message: t("ThankYouYourReviewHelpsUs"), severity: "success" });
        }
    });

    const sendEnabled = useMemo(() => rating.remark?.length && rating.value > 0, [rating]);

    const isSodobar = useMemo(() => location.name?.toLowerCase().includes("sodobar"), [location]);

    return (
        <Container maxWidth="md">
            <CardHeader title={location.name} subheader={t("Building") + ": " + location?.building + ", " + t("Floor") + ": " + location?.floor} sx={{ mt: 2 }} />

            <Card >
                <CardContent>
                    <Typography>{t(isSodobar ? "NotesOnSodobar" : "EvaluationOfCleaning")} *</Typography>
                    <Rating value={rating.value} size="large" onChange={(e, newValue) => { setRating({ ...rating, value: newValue ?? 0 }); }} />

                    <TextField label={t("Remark")} multiline variant="standard" fullWidth value={rating.remark} onChange={(e) => setRating({ ...rating, remark: e.target.value })} required />

                    <FormGroup sx={{ mt: 1 }}>
                        <FormControlLabel control={<Checkbox onChange={e => setRating({ ...rating, sendByEmail: e.target.checked })} />} label={t("NotifyAdministrationDepartment")} checked={rating.sendByEmail} />
                    </FormGroup>

                    <UploadFile subId={rating.id} sx={{ my: 1 }} />
                    <FileViewer subId={rating.id} />
                </CardContent>

                <CardActions sx={{ float: 'right' }}>
                    <LoadingButton variant="contained" onClick={() => mutate(rating)} disabled={!sendEnabled}>{t("Send")}</LoadingButton>
                </CardActions>
            </Card>
        </Container>
    );
}